import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "react-bootstrap";

const UserVerifyModal = ({
  isUserVerifyModal,
  setIsUserVerifyModal,
  type,
  idVal,
  handleVerifyUser,
}) => {
  return (
    <>
      <Modal
        show={isUserVerifyModal}
        onHide={setIsUserVerifyModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete_account_modal"
      >
        <Modal.Body>
          <h4 className="text-center mb-0 delete_content">
            Are you sure you want to {type == 1 ? `Verify ?` : `Unverify ?`}
          </h4>
          <div className="d-flex justify-content-center delete_acc_btns">
            <button
              onClick={() => handleVerifyUser(idVal, type)}
              type="submit"
              className="btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn"
            >
              Yes
            </button>
            <button
              onClick={() => setIsUserVerifyModal(false)}
              type="submit"
              className="btn btn-primary btn-custom btn-lg submit_btn confirmation_btn"
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UserVerifyModal;
