import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Offcanvas } from "react-bootstrap";
import { CSVLink } from "react-csv";

import { IMAGES } from "../constant/theme";
import InviteCustomer from "../constant/ModalList";
import EmployeeOffcanvas from "../constant/EmployeeOffcanvas";
import Layout from "../components/layouts/Layout";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
import {
  getVerificationUsers,
  verifyUser,
} from "../../store/actions/AdminAction";
import moment from "moment";
import Search from "../components/elements/Search";
import Placholder from "../../images/placholder-img-admin1.png";
import ImagePreviewModal from "../Modal/ImagePreviewModal";
import UserVerifyModal from "../Modal/UserVerfiyModal";
import { toast } from "react-toastify";

const UserVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL + "/";
  const limit = 7;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [imageModal, setImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [isUserVerifyModal, setIsUserVerifyModal] = useState(false);
  const [type, setType] = useState("");
  const [idVal, setIdVal] = useState("");

  const allUsers = useSelector((state) => state?.adminReducer?.verifyUserList);
  const totalUsers = useSelector(
    (state) => state?.adminReducer?.totalVerifyUser
  );
  const lastIndex = page * limit;
  const npage = Math.ceil(totalUsers / limit);
  const number = npage ? [...Array(npage + 1).keys()].slice(1) : [1];

  useEffect(() => {
    dispatch(getVerificationUsers(page, limit, search));
  }, [page, limit, search]);

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }

  const handleVerifyUser = (id, admin_verified) => {
    dispatch(verifyUser({ id, admin_verified }))
    setIsUserVerifyModal(false);
  };

  const invite = useRef();
  const employe = useRef();

  const handlePrivewImagesOpen = (img) => {
    setImageModal(true);
    setSelectedImage(img);
  };

  const handlePrivewImagesClose = () => {
    setImageModal(false);
    setSelectedImage("");
  };

  return (
    <Layout>
      <MainPagetitle
        mainTitle="User Verification"
        pageTitle={"User Verification"}
        parentTitle={"Home"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">User Verification</h4>
                    <Search setSearch={setSearch} setPage={setPage} />
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer h-fixed-550"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>User Name</th>
                          <th>Email Address</th>
                          {/* <th>Contact Number</th> */}
                          <th>Front Image</th>
                          <th>Back Image</th>
                          <th>Selfie</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allUsers?.length > 0 ? (
                          allUsers?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span>{index + lastIndex - limit + 1}</span>
                              </td>
                              <td>
                                <div className="products">
                                  <img
                                    src={
                                      item?.profile_image
                                        ? IMG_URL + item?.profile_image
                                        : Placholder
                                    }
                                    className="avatar avatar-md"
                                    alt=""
                                  />
                                  <div
                                    className="pointerUser"
                                    onClick={() =>
                                      navigate(`/user-detail/${item._id}`)
                                    }
                                  >
                                    <h6>
                                      {" "}
                                      {item?.full_name.concat(
                                        " ",
                                        item?.last_name
                                      )}
                                    </h6>
                                    <span>User</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className="text-primary ">
                                  {item?.email}
                                </span>
                              </td>
                              <td>
                                <div className="products">
                                  <img
                                    src={IMG_URL + item?.front_image}
                                    className="avatar avatar-md"
                                    alt=""
                                    onClick={() =>
                                      item?.front_image &&
                                      handlePrivewImagesOpen(item?.front_image)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="products">
                                  <img
                                    src={IMG_URL + item?.back_image}
                                    className="avatar avatar-md"
                                    alt=""
                                    onClick={() =>
                                      item?.back_image &&
                                      handlePrivewImagesOpen(item?.back_image)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="products">
                                  <img
                                    src={IMG_URL + item?.selfie}
                                    className="avatar avatar-md"
                                    alt=""
                                    onClick={() =>
                                      item?.selfie &&
                                      handlePrivewImagesOpen(item?.selfie)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                {item?.admin_verified == 3 ? (
                                  <div className="action-btn-mk">
                                    <button
                                      onClick={() => {
                                        setType(1);
                                        setIdVal(item?._id);
                                        setIsUserVerifyModal(true);
                                        // handleVerifyUser(item?._id, 1);
                                      }}
                                      className="btn btn-primary me-2 verify-btn-mk"
                                    >
                                      Verify
                                    </button>
                                    <button
                                      onClick={() => {
                                        setType(2);
                                        setIdVal(item?._id);
                                        setIsUserVerifyModal(true);
                                        // handleVerifyUser(item?._id, 2);
                                      }}
                                      className="btn btn-primary verify-btn-mk"
                                    >
                                      Unverify
                                    </button>
                                  </div>
                                ) : item?.admin_verified == 1 ? (
                                  <div className="action-btn-mk">
                                    <button
                                      className="btn btn-primary me-2 verify-btn-mk"
                                      disabled
                                    >
                                      Verified
                                    </button>
                                    <button
                                      onClick={() => {
                                        setType(2);
                                        setIdVal(item?._id);
                                        setIsUserVerifyModal(true);
                                        // handleVerifyUser(item?._id, 2);
                                      }}
                                      className="btn btn-primary verify-btn-mk"
                                    >
                                      Unverify
                                    </button>
                                  </div>
                                ) : (
                                  item?.admin_verified == 2 && (
                                    <button
                                      className="btn btn-primary verify-btn-mk"
                                      disabled
                                    >
                                      Unverified
                                    </button>
                                  )
                                )}
                              </td>
                              {/* <td>
                                <span>
                                  {item?.country_code +
                                    " " +
                                    item?.phone_number}
                                </span>
                              </td> */}
                              {/* <td>
                                <span>
                                  {" "}
                                  {moment(item?.lastLogin).format(
                                    "Do MMMM YYYY"
                                  )}
                                </span>
                              </td> */}
                              {/* <td>
                                <span>
                                  {item?.login_type == 1
                                    ? "Apple"
                                    : item?.login_type == 2
                                    ? "Google"
                                    : item?.login_type == 3
                                    ? "Facebook"
                                    : item?.login_type == 0 && "General"}
                                </span>
                              </td> */}
                              {/* <td>
                                <span
                                  className={`badge light border-0 ${
                                    item.suspended === 0
                                      ? "badge-success"
                                      : "badge-warning"
                                  }`}
                                >
                                  {item.suspended === 0
                                    ? "Active"
                                    : "Suspended"}
                                </span>
                              </td> */}
                            </tr>
                          ))
                        ) : (
                          <td colspan="10">
                            <p className="no_content_table">No Users yet</p>
                          </td>
                        )}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {totalUsers == 0 ? 0 : lastIndex - limit + 1} to{" "}
                        {totalUsers < lastIndex ? totalUsers : lastIndex} of{" "}
                        {totalUsers} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UserVerifyModal
        isUserVerifyModal={isUserVerifyModal}
        setIsUserVerifyModal={setIsUserVerifyModal}
        handleVerifyUser={handleVerifyUser}
        type={type}
        idVal={idVal}
      />
      <ImagePreviewModal
        handlePrivewImagesClose={handlePrivewImagesClose}
        imageModal={imageModal}
        selectedImage={selectedImage}
      />

      <EmployeeOffcanvas ref={employe} Title="Add Employee" />
      <InviteCustomer ref={invite} Title="Invite Employee" />
    </Layout>
  );
};

export default UserVerification;
